<template lang="pug">
.aside-menu
  .nav-menu
    template(v-for="tab in tabs" :key="tab.key")
      template(v-if="tab.available && tab.show")
        .nav-menu__border(v-if="tab.highlight")
        nav-menu-link(:tab="tab")

  .nav-menu.footer
    nav-menu-item(v-if="!isTmManager" :icon="UiIconNames.Icon_Support" label="Контакты")
      template(#content)
        .aside__footer
          .contact-us
            span Свяжитесь с нами:
            support-contacts(size="small")
          span.mini Наши специалисты проконсультируют Вас по работе с системой и ответят на все Ваши вопросы.
    nav-menu-item(v-if="!isTmManager" :icon="UiIconNames.Icon_DocumentCheck2" label="Соглашения")
      template(#content)
        .footer-info
          a.link.agreement(target="_blank" href="https://nposys.ru/policy/") Политика конфиденциальности
          a.link.agreement(target="_blank" href="https://nposys.ru/agreement/") Пользовательское соглашение

    .version
      span Версия:
      accessed-link(:name="Tabs.ReleaseNotesFront") {{ version }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import { Tabs } from "@/router/tabs";
import { version } from '~/package.json'
import UiIconNames from "@/components/ui/icon/UiIconNames";

import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

import NavMenuItem from "~/components/mainMenu/NavMenuItem.vue";
import NavMenuLink from "@/components/mainMenu/NavMenuLink.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";
import SupportContacts from "~/components/support/SupportContacts.vue";

export default defineComponent({
  name: "NavMenu",
  components: {
    NavMenuItem,
    NavMenuLink,
    AccessedLink,
    SupportContacts,
  },
  setup() {

    const interfaceSettingsStore = useInterfaceSettingsStore();
    const { tabs } = storeToRefs(interfaceSettingsStore);

    const { isTmManager } = useUserAccess();

    return {
      tabs,
      version,
      isTmManager,
      Tabs,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.link {
  @include link-mixin;
}

.aside-menu {
  display: flex;
  flex-flow: column;
  width: 120px;

  padding: 16px 8px 12px 8px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #F1F1F2;
}

.nav-menu {
  display: grid;
  gap: 4px;
  align-items: center;
  height: fit-content;

  &.footer {
    margin-top: auto;
    padding: 12px 0;
  }
}

.nav-menu__border {
  width: 16px;
  height: 1px;
  background-color: var(--main-text-color);
  margin: 24px 12px;
  justify-self: center;
}

.version {
  display: flex;
  gap: 4px;
  font-size: 12px;
  align-self: center;
  justify-content: center;
  margin-top: 12px;
}

.footer-info {
  display: flex;
  flex-flow: column;
  gap: 4px;
  font-size: 11px;
  line-height: 14px;
}

.agreement {
  padding: 4px 8px;
}

.aside__footer {
  display: flex;
  flex-flow: column;
  gap: 16px;

  font-size: 13px;
  padding: 8px;
  max-width: 360px;

  .mini {
    font-size: 11px;
    line-height: 14px;
  }

  .contact-us {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
  }
}
</style>
